<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Keyword*</label>
					<vs-input class="w-full" v-model="formData['keyword']"/>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Category*</label>
					<v-select class="w-full" v-model="formData['category']" :options="formInit['categories']"></v-select>
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading" class="mb-2" @click="openConfirm()" :disabled="user_role != 'admin' || (formData['keyword'] == '' || formData['category'] == '')">Submit</vs-button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default{
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				formData: {
					keyword: '',
					category: {
						label: 'Generic',
						value: 'generic'
					},
				},
				formInit: {
					categories: [
						{
							label: 'Brand',
							value: 'brand'
						},
						{
							label: 'Generic',
							value: 'generic'
						}
					],
				},
			}
		},
		watch: {
			
		},
		computed: {
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Add this Blacklist Keyword?',
					accept: this.addBlacklistKeyword
				});
			},
			addBlacklistKeyword() {
				let self = this;
				// Show loader
				self.showButtonLoading();
				// Post Axios Call
				this.$axiosSecure.post('/save-trademarked-keyword', {
					keyword: self.formData['keyword'],
					category: self.formData['category']['value'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Blacklist keyword addition success',
						text: 'The Blacklist keyword has been added'
					});
					
					// Hide loader
					self.hideButtonLoading();
					self.$router.push({ path: '/blacklist-keyword-list' });
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		mounted() {
			
		}
	}
</script>
